// @ts-check
// Cynhyrchwyd y ffeil hon yn awtomatig. PEIDIWCH Â MODIWL
// This file is automatically generated. DO NOT EDIT

export function ApiServer() {
  return window['go']['main']['App']['ApiServer']();
}

export function CorsServer() {
  return window['go']['main']['App']['CorsServer']();
}
